$(document).ready(function() {
  $(document).on('sku:select', function(e, skuData) {
    //Down Price Updated for Shaded Products 
    var formattedPrice = (_.isUndefined(skuData.formattedPrice)) ? '' : skuData.formattedPrice;
    var formattedTaxedPrice = (_.isUndefined(skuData.formattedTaxedPrice)) ? '' : skuData.formattedTaxedPrice;
    var formattedFuturePrice = (_.isUndefined(skuData.formattedFuturePrice)) ? '' : skuData.formattedFuturePrice;
    var productSize = (_.isUndefined(skuData.PRODUCT_SIZE)) ? '' : skuData.PRODUCT_SIZE;
    var priceNode = $('.spp-product__price-size');
    
    var optionTextHtml = '<span class="product__price">'+ '税込' +skuData.formattedTaxedPrice +'  ' + '( 本体'+skuData.formattedPrice+ ' )' +'</span>';
    priceNode.html(optionTextHtml);
  });

  site.productView.updateBis = function($listNode) {
    var that = {
        $listNode : $listNode
    };
    that.productId = that.$listNode.attr("data-product-id");
    that.$listNode.on("inventory_status_stored", function(e) {
        that.updateBisButtons();
        e.stopPropagation();
    });
    that.updateBisButtons = function() {
        if(Drupal.settings.enableNotifyMe != "undefined" && Drupal.settings.enableNotifyMe){
            $('.notify_me',that.$listNode).show();
        }
        else {
            $('.notify_me', that.$listNode).hide();
        }
    };
    that.downPriceUpdate = function(skuData) {
      //Down Price Updated for Shaded Products 
      var formattedPrice = (_.isUndefined(skuData.formattedPrice)) ? '' : skuData.formattedPrice;
      var formattedTaxedPrice = (_.isUndefined(skuData.formattedTaxedPrice)) ? '' : skuData.formattedTaxedPrice;
      var formattedFuturePrice = (_.isUndefined(skuData.formattedFuturePrice)) ? '' : skuData.formattedFuturePrice;
      var productSize = (_.isUndefined(skuData.PRODUCT_SIZE)) ? '' : skuData.PRODUCT_SIZE;
      var priceNode = $('.quickshop-inline__price-size').not('select');

      var optionTextHtml = '<span class="product__price">'+ '税込' +skuData.formattedTaxedPrice +'  ' + '( 本体'+skuData.formattedPrice+ ' )' +'</span>';
      priceNode.html(optionTextHtml);
    };

    that.updateBisButtons();
    that.$listNode.on('sku:select', function(e, skuData) {
        that.$listNode.attr("data-sku-id", skuData.SKU_ID);
        that.updateBisButtons();
        that.downPriceUpdate(skuData);
        e.stopPropagation();
    });
    return that;
  };
});

